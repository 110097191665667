import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 30px;
`

export default function BeerPage({ data }) {
  // const beers = data.allBeer.nodes
  return (
    <p>gugus</p>
    // <StyledGrid className="container my-12">
    //   {beers.map(beer => (
    //     <div class="d-flex flex-column align-items-center justify-content-end mb-12">
    //       <img
    //         className="mb-4"
    //         src={beer.image}
    //         style={{ width: "50px", height: "auto" }}
    //       />
    //       <p className="text-center px-5">{beer.name}</p>
    //     </div>
    //   ))}
    // </StyledGrid>
  )
}

// export const pageQuery = graphql`
//   query AllBeerData {
//     allBeer(sort: { fields: [name], order: DESC }) {
//       nodes {
//         name
//         price
//         image
//       }
//     }
//   }
// `
